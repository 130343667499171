@import './font-face.css';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    height: 100%;
    background-color: var(--text-color);
}

.wrapper {
    min-height: 100%;
}

:root {
    --text-color:#1d345c;
}

hr {
  margin: 0 auto;
  border: 1px solid #ffffff;
  width: 90%;
}

/* ===== Spacing ===== */
$max: 1000;
$rem: 625;

@for $unit from 0 through $max {
  $unitRem: $rem * $unit;

  /* Margin */
  .m-#{$unitRem}rem {
    margin: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .mt-#{$unitRem}rem {
    margin-top: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .mb-#{$unitRem}rem {
    margin-bottom: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .ms-#{$unitRem}rem {
    margin-left: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .me-#{$unitRem}rem {
    margin-right: calc($rem / 10000 * $unit) * 1rem !important;
  }

  /* Padding */
  .p-#{$unitRem}rem {
    padding: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .pt-#{$unitRem}rem {
    padding-top: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .pb-#{$unitRem}rem {
    padding-bottom: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .ps-#{$unitRem}rem {
    padding-left: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .pe-#{$unitRem}rem {
    padding-right: calc($rem / 10000 * $unit) * 1rem !important;
  }

  /* Height */
  .h-#{$unitRem}rem {
    height: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .max-h-#{$unitRem}rem {
    max-height: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .min-h-#{$unitRem}rem {
    min-height: calc($rem / 10000 * $unit) * 1rem !important;
  }

  /* Width */
  .w-#{$unitRem}rem {
    width: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .max-w-#{$unitRem}rem {
    max-width: calc($rem / 10000 * $unit) * 1rem !important;
  }

  .min-w-#{$unitRem}rem {
    min-width: calc($rem / 10000 * $unit) * 1rem !important;
  }

    /* Line Height */
    .line-h-#{$unitRem}rem {
      line-height: calc($rem / 10000 * $unit) * 1rem !important;
    }

  /* Font-Size */
  .fs-#{$unitRem}rem {
    font-size: calc($rem / 10000 * $unit) * 1rem !important;
  }
}
